/*! contact.js | Bulkit | CSS Ninja */

/* ==========================================================================
Google maps init JS file
========================================================================== */

$(document).ready(function ($) {

    "use strict";

    initGoogleMap();

})